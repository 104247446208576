import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"

const DegreeTemplate = ({ data }) => (
    <Layout>
        <h1>{data.degree.degree_type}</h1>
        <h2>{data.degree.relationships.institution.name} {data.degree.year_received}</h2>
        <h3>{data.degree.location.city}, {data.degree.location.state}</h3>
    </Layout>
)

export default DegreeTemplate

export const query = graphql`
    query($slug: String!) {
        degree: nodeEducation(fields: { slug: { eq: $slug } }) {
            degree_type: field_edu_degree
            relationships {
                institution: field_edu_institution {
                name
                }
            }
            year_received: field_edu_year_received
            location: field_location_city {
                city: location_city_city
                state: location_city_state
            }
        }
    }
`